import * as React from 'react';
import Title from "../Common/Title";
import {StaticImage} from "gatsby-plugin-image";
import Slider from "react-slick";
import {getImageAltText} from "../../utils/common";

const sliderSettings = {
  className: "slider",
  dots: false,
  infinite: true,
  fade: true,
  lazyLoad: false,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  swipeToSlide: true,
  slidesToShow: 3
};

const BeforeAfter = () => (
  <div className="panel mb-5 ">
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-md-11">
          <div className="mb-4">
            <Title>Результати роботи</Title>
          </div>
          <p className="mb-9 text-muted">&nbsp;&nbsp;
            Здійснюйте запис за телефоном, або залиште заявку на сайті.
            Наші співробітники зв'яжуться з Вами та підберуть <span>зручний час</span> для відвідування нашої Академії,
            а також <span>проконсультують</span> згідно з інформацією про надання медичних послуг.
          </p>
          <div className="row beforeAfter">
            <Slider {...sliderSettings}>
              <StaticImage src="../../assets/images/before_after/ba1.jpeg" placeholder="blurred" className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
              <StaticImage src="../../assets/images/before_after/ba2.jpeg" placeholder="blurred" className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
              <StaticImage src="../../assets/images/before_after/ba5.jpeg" placeholder="blurred" className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </Slider>

          </div>
          <div className="row beforeAfterVideo">
            <div className="col-6">
              <video controls>
                <source src="https://drive.google.com/uc?id=1xUwAcfuM9v_9RBYLv5TcvED93OMq23U2" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-6">
              <video controls>
                <source src="https://drive.google.com/uc?id=1r07GTBVxiRQkUCQ9Vz3aMdIlrS-xYxKi" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-6">
              <video controls>
                <source src="https://drive.google.com/uc?id=12Qhkmu-mnsXnWo-4H5m2xjtxdo4Ic4if" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="col-6">
              <video controls>
                <source src="https://drive.google.com/uc?id=1-Ix_ZaBB9_x1X3pduNFXLDxuF_4sKUGb" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BeforeAfter;