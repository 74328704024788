import * as React from 'react';
import Slider from "react-slick";

const sliderSettings = {
    className: "slider",
    dots: false,
    infinite: true,
    fade: true,
    lazyLoad: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    swipeToSlide: true,
};

const Hero = () => (
  <header className="mb-5">
    <div className="d-none d-md-block">
      <Slider {...sliderSettings}>
        {/*<div className="hero-wrapper">*/}
        {/*  <div className="prokto"></div>*/}
        {/*    <div className="prokto-info"></div>*/}
        {/*    <h1 className="prokto-text-title">ЛІКУВАННЯ В КАБІНЕТІ ПРОКТОЛОГА</h1>*/}
        {/*    <h1 className="prokto-text">НОВА ПРОФЕСІЙНА ПОСЛУГА!</h1>*/}
        {/*</div>*/}
        <div className="hero-wrapper">
          <div className="team"></div>
            <div className="team-info"></div>
            <h1 className="team-text-title">АКАДЕМІЯ ЛАЗЕРНОЇ МЕДИЦИНИ</h1>
            <h1 className="team-text">ЯКІСНА ТА ПРОФЕСІЙНА ДОПОМОГА!</h1>
        </div>
        {/*<div className="hero-wrapper">*/}
        {/*    <div className="hero-back"></div>*/}
        {/*    <div className="hero-info"></div>*/}
        {/*    <div className="hero-front"></div>*/}
        {/*    <h1 className="hero-text">ЗАПРОШУЄМО<br/>ВІДВІДАТИ<br/>НАШ ЦЕНТР<br/>У МІСТІ ЧЕРКАСИ!</h1>*/}
        {/*</div>*/}
        <div className="hero-wrapper">
            <div className="doctors"></div>
            <div className="doctors-info"></div>
            <h1 className="doctors-text">ДОСВІДЧЕНІ СПЕЦІАЛІСТИ<br/>ТА СУЧАСНИЙ СЕРВІС!</h1>
        </div>
        <div className="hero-wrapper">
          <div className="office"></div>
          <div className="office-info"></div>
          <h1 className="office-text">ЗАПРОШУЄМО ВІДВІДАТИ<br/>НАШ ЦЕНТР У МІСТІ ЧЕРКАСИ!</h1>
        </div>
      </Slider>
    </div>
    <div className="d-block d-md-none">
      {/*<div className="hero-wrapper">*/}
      {/*  <div className="hero-back"></div>*/}
      {/*  <div className="hero-info"></div>*/}
      {/*  <div className="hero-front"></div>*/}
      {/*  <h1 className="hero-text">ЗАПРОШУЄМО<br/>ВІДВІДАТИ<br/>НАШ ЦЕНТР<br/>У МІСТІ ЧЕРКАСИ!</h1>*/}
      {/*</div>*/}
      {/*<div className="hero-wrapper">*/}
      {/*  <div className="office"></div>*/}
      {/*  <div className="office-info"></div>*/}
      {/*  <h1 className="office-text">ЗАПРОШУЄМО ВІДВІДАТИ<br/>НАШ ЦЕНТР У МІСТІ ЧЕРКАСИ!</h1>*/}
      {/*</div>*/}

      <div className="hero-wrapper">
        <div className="team"></div>
        <div className="team-info"></div>
        <h1 className="team-text-title">АКАДЕМІЯ ЛАЗЕРНОЇ МЕДИЦИНИ</h1>
      </div>
      <br/>
      {/*<div className="hero-wrapper">*/}
      {/*  <div className="prokto"></div>*/}
      {/*  <div className="prokto-info"></div>*/}
      {/*  <h1 className="prokto-text-title">ЛІКУВАННЯ У ПРОКТОЛОГА</h1>*/}
      {/*  <h1 className="prokto-text">НОВА ПОСЛУГА!</h1>*/}
      {/*</div>*/}
    </div>
  </header>
);

export default Hero;