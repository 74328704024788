import * as React from 'react';
import Title from "../Common/Title";
import {StaticImage} from "gatsby-plugin-image";
import {getImageAltText} from "../../utils/common";

const OurSpecialists = () => (
  <div className="panel mb-5 ourSpecialists">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 text-center">
          <div className="mb-3">
            <Title>Наші Спеціалісти</Title>
            <p className="text-muted">&nbsp;&nbsp;За роки роботи медичної установи лікарям вдалося не лише впровадити в
              практику багато методик у флебології, дерматохірургії, проктології, гінекології, а й
              <span>заслужити довіру</span> пацієнтів і колег з усієї України.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="team">
            <div className="team-avatar">
              <StaticImage src="../../assets/images/doctors/d6.jpg" className="img-fluid w-100" placeholder="blurred"
                           alt={getImageAltText()}/>
            </div>
            <div className="team-content">
              <div className=" team-content-inner panel">
                <div className="d-flex">
                  <div className="team-info">
                    <h5 className="mb-0">Савченко Андрій Миколайович</h5>
                  </div>
                </div>
                <div className="team-contact">
                  <hr/>
                  <div className="team-position">Лікар – хірург вищої категорії, доктор медичних наук, професор</div>
                  <div className="team-add">
                    стаж роботи 38 років
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="team">
            <div className="team-avatar">
              <StaticImage src="../../assets/images/doctors/d1.jpg" className="img-fluid w-100" placeholder="blurred"
                           alt={getImageAltText()}/>
            </div>
            <div className="team-content">
              <div className=" team-content-inner panel">
                <div className="d-flex">
                  <div className="team-info">
                    <h5 className="mb-0">Горошко Сергій Васильович</h5>
                  </div>
                </div>
                <div className="team-contact">
                  <hr/>
                  <div className="team-position">Лікар хірург вищої категорії</div>
                  <div className="team-add">
                    стаж роботи 14 років
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="team">
            <div className="team-avatar">
              <StaticImage src="../../assets/images/doctors/d3.jpg" className="img-fluid w-100" placeholder="blurred"
                           alt={getImageAltText()}/>
            </div>
            <div className="team-content">
              <div className=" team-content-inner panel">
                <div className="d-flex">
                  <div className="team-info">
                    <h5 className="mb-0">Мироненко Альона Сергіївна</h5>
                  </div>
                </div>
                <div className="team-contact">
                  <hr/>
                  <div className="team-position">Cудинний хірург 2 категорії</div>
                  <div className="team-add">
                    стаж роботи 8 років
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="team">
              <div className="team-avatar">
                <StaticImage src="../../assets/images/doctors/d4.jpg" className="img-fluid w-100" placeholder="blurred"
                             alt={getImageAltText()}/>
              </div>
              <div className="team-content">
                <div className=" team-content-inner panel">
                  <div className="d-flex">
                    <div className="team-info">
                      <h5 className="mb-0">Похилюк Роман Геннадійович</h5>
                    </div>
                  </div>
                  <div className="team-contact">
                    <hr/>
                    <div className="team-position">Лікар-комбустіолог вищої категорії</div>
                    <div className="team-add">
                      стаж роботи 13 років
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="team">
              <div className="team-avatar">
                <StaticImage src="../../assets/images/doctors/d2.jpg" className="img-fluid w-100" placeholder="blurred"
                             alt={getImageAltText()}/>
              </div>
              <div className="team-content">
                <div className=" team-content-inner panel">
                  <div className="d-flex">
                    <div className="team-info">
                      <h5 className="mb-0">Ковбасій Валентина Петрівна</h5>
                    </div>
                  </div>
                  <div className="team-contact">
                    <hr/>
                    <div className="team-position">Лікар акушер – гінеколог вищої категорії, кандидат медичних наук
                    </div>
                    <div className="team-add">
                      стаж роботи 24 років
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    );

    export default OurSpecialists;