import * as React from 'react';
import Title from "../Common/Title";
import {StaticImage} from "gatsby-plugin-image";
import {getImageAltText} from "../../utils/common";

const Gallery = () => (
  <div className="panel mb-5 ">
    <div className="container">
      <div className="row text-center justify-content-center">
        <div className="col-md-11">
          <div className="mb-4">
            <Title>Академія Лазерної Медицини</Title>
          </div>
          <p className="mb-9 text-muted">&nbsp;&nbsp;
            Академія лазерної медицини плідно співпрацює з <span>ПП «Фотоніка Плюс»</span> та
            практично є клінічною базою національного виробника лазерної техніки. Це дозволяє
            досить швидко впроваджувати у практику <span>найновітніші напрацювання та методики</span> у
            лікуванні різних захворювань.
          </p>
          <div className="row">
            <div className="col-4">
              <StaticImage src="../../assets/images/gallery/p1.jpg" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </div>
            <div className="col-3">
              <StaticImage src="../../assets/images/gallery/p2.jpg" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
              <StaticImage src="../../assets/images/gallery/p3.jpg" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </div>
            <div className="col-5">
              <div className="row mb-2">
                <div className="col-5">
                  <StaticImage src="../../assets/images/gallery/p4.jpg" placeholder="blurred"
                               className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
                </div>
                <div className="col-7">
                  <StaticImage src="../../assets/images/gallery/work2.png" placeholder="blurred"
                               className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
                </div>
              </div>
              <StaticImage src="../../assets/images/gallery/p6.jpg" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <StaticImage src="../../assets/images/gallery/work3.png" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </div>
            <div className="col-6">
              <StaticImage src="../../assets/images/gallery/work4.png" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </div>
            <div className="col-4">
              <StaticImage src="../../assets/images/gallery/work1.png" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </div>
            <div className="col-8">
              <StaticImage src="../../assets/images/gallery/work5.png" placeholder="blurred"
                           className="img-fluid rounded shadow-lg transition-hover" alt={getImageAltText()}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Gallery;