import React from 'react';
// import {Link} from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/Homepage/Hero";
import FourInfoBlocks from "../components/Homepage/FourInfoBlocks";
import About from "../components/Homepage/About";
import Certificates from "../components/Homepage/Certificates";
import OurSpecialists from "../components/Homepage/OurSpecialists";
import Gallery from "../components/Homepage/Gallery";
import BeforeAfter from "../components/Homepage/BeforeAfter";
import Prokto from "../components/Homepage/Prokto";
// import FAQ from "../components/Homepage/faq";

typeof window !== 'undefined' && window.localStorage.setItem('modal_status', '');

const IndexPage = () => (
  <Layout>
    <div className="home">
      <Seo title="Головна"/>
      <Hero/>
      <FourInfoBlocks/>
      {/*<Prokto/>*/}
      <About/>
      <Certificates/>
      <OurSpecialists/>
      <Gallery/>
      <BeforeAfter/>
      {/*<FAQ/>*/}
    </div>

    {/*<section>*/}
    {/*  <div className="row py-lg-5">*/}
    {/*    <div className="col-lg-6 col-md-8 mx-auto">*/}
    {/*      <h1 className="fw-light">Hello world ! </h1>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*  <div className="row">*/}
    {/*    <Link to="/about/" className="btn btn-primary my-2">About</Link>*/}
    {/*    <Link to="/weather/" className="btn btn-secondary my-2">Go to weather</Link>*/}
    {/*  </div>*/}
    {/*</section>*/}
  </Layout>
)

export default IndexPage;